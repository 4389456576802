@font-face {
    font-family: Adagio_Sans;
    font-weight: bolder;
    src: url("./assets/fonts/AdagioSans/Fonts/adagio_sans-black.otf");
}

@font-face {
    font-family: Adagio_Sans;
    font-weight: bold;
    src: url("./assets/fonts/AdagioSans/Fonts/adagio_sans-bold.otf");
}

@font-face {
    font-family: Adagio_Sans;
    font-size: medium;
    src: url("./assets/fonts/AdagioSans/Fonts/adagio_sans-medium.otf");
}

@font-face {
    font-family: Adagio_Sans;
    font-weight: normal;
    src: url("./assets/fonts/AdagioSans/Fonts/adagio_sans-regular.otf");
}

@font-face {
    font-family: Adagio_Sans;
    font-weight: 100;
    src: url("./assets/fonts/AdagioSans/Fonts/adagio_sans-thin.otf");
}

html {
    font-size: 17px;
}

body {
    font-family: Adagio_Sans, myriad-pro, sans-serif;
    font-size: 1rem;
}

hr {
    margin-top: 0px;
}