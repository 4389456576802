/*===============
Sign In Container
===============*/
.sign-in-container {
    width: 70%;
    height: fit-content;
    margin: 2em auto;
    border-radius: .7rem;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);

    animation: onLoadAnimation 1s;
}

.userDashboard-container {
    animation: onLoadAnimation 1s;
}

@keyframes onLoadAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/*==
Form
==*/
.sign-in-container form {
    padding: 2em 0;
}

.sign-in-container form .form-group {
    width: 80%;
    margin: 0.3em auto;
}

.sign-in-container form .form-group label {
    color: #22428f;
    margin-top: 0.5em;
    text-shadow: 0 6px 13px rgba(0, 0, 0, 0.2);
}

.sign-in-container form .form-group input {
    color: #282c34;
}

.sign-in-container form .form-group .form-check {
    margin-top: 0.3em;
}
.sign-in-container form .form-group .form-check label {
    color: #282c34;
    margin-top: 0;
}

/*-------
Recaptcha
 ------*/
.sign-in-container form .recaptcha {
    margin-top: 1em;
    margin-bottom: 1em;
    font-size: 1rem;
}
.sign-in-container form .recaptcha .error-msg {
    color: red;
    margin: 0.25em 5em;
    text-shadow: 0 6px 13px rgba(0, 0, 0, 0.2);
}

.sign-in-container form .recaptcha #recaptcha-container > div > div {
    margin: auto;
    background: white;
}

/*----------------------
Phone Code Error Message
 ---------------------*/
.sign-in-container form  .phone-code.error-msg {
    color: red;
    margin: 0.25em 5em;
    text-shadow: 0 6px 13px rgba(0, 0, 0, 0.2);
}

/*-------------
Password Forget
 ------------*/
.sign-in-container form .pw-forget.form-text {
    color: #282c34;
    cursor: pointer;
    text-decoration: underline;
}

.sign-in-container form .pw-forget.form-text:hover {
    color: #22428f;
}

.sign-in-container form .pw-forget.form-text:hover i {
    color: #22428f;
}

.sign-in-container form .pw-forget.form-text i {
    color: #282c34;
    padding-left: 0.3em;
}

/*-----------
Media Queries
 ----------*/
@media (max-width: 530px) {
    .sign-in-container {
        width: 95%;
    }

    .sign-in-container form .form-group {
        width: 90%;
        margin: 0 auto;
    }
}

/*-----------
Submit Button
 ----------*/
.sign-in-container form button {
    background: #22428f;
    color: white;
    margin-top: 1em;
    padding: 0.5em 1em;
    border-radius: 0.7rem;
    border: none;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);

    transition: all 200ms;
}

.sign-in-container form button:focus {
    outline: 0;
    background: #A2AAAD;
    box-shadow: 2px 5px 11px rgba(0, 0, 0, 0.2);
}

.sign-in-container form button:hover {
    background: #22428f;
    box-shadow: 2px 5px 11px rgba(0, 0, 0, 0.2);
}