/*=======================
Previous Status Container
 ======================*/
.previous-status-container {
    animation: onLoadAnimation 1s;
}

@keyframes onLoadAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/*=====
Heading
 ====*/
.previous-status-container > .heading {
    margin: 1em;
}

.previous-status-container .heading h5 {
    color: #22428f;
    text-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
}

/*---------
Media Query
 --------*/
@media (max-width: 1150px) {
    .previous-status-container .heading h5 {
        font-size: 1.15rem;
    }
}

@media (max-width: 1000px) {
    .previous-status-container .heading h5 {
        font-size: 1.10rem;
    }
}

@media (max-width: 830px) {
    .previous-status-container .heading h5 {
        font-size: 1.10rem;
    }
}

@media (max-width: 700px) {
    .previous-status-container .heading h5 {
        margin-bottom: 0.4rem;
        font-size: 0.90rem;
    }
}

@media (max-width: 390px) {
    .previous-status-container .heading h5 {
        margin-bottom: 0.3rem;
        font-size: 0.80rem;
    }
}

@media (max-width: 330px) {
    .previous-status-container .heading h5 {
        margin-bottom: 0.2rem;
        font-size: 0.70rem;
    }
}

/*===========
Selected Time
 ==========*/
.previous-status-container .selected-time {
    display: flex;
    margin: 0 auto;
    width: fit-content;
}

/*-------
Selectors
-------*/
.previous-status-container .selected-time .selectors {
    border-radius: 0.7rem;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);

    transition: box-shadow 200ms;
}

.previous-status-container .selected-time .selectors:hover {
    box-shadow: 2px 5px 11px rgba(0, 0, 0, 0.2);
}

.previous-status-container .selected-time .selectors > select {
    margin: 0.5em;
    height: 1.5em;
    background: transparent;
    border: none;
    /*text-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);*/
    /*box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);*/
}

/*---------------------
Selectors Search Button
---------------------*/
.previous-status-container .selected-time .search-button > button {
    background: #22428f;
    padding: 0.5em 0.7em;
    margin-left: 1em;
    color: white;
    border-radius: 0.7rem;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);

    transition: box-shadow 200ms, background 200ms, outline 200ms;
}

.previous-status-container .selected-time .search-button > button:focus {
    outline: none;
    background: #A2AAAD;
}

.previous-status-container .selected-time .search-button > button:hover {
    box-shadow: 2px 5px 11px rgba(0, 0, 0, 0.2);
}

/*---------
Media Query
 --------*/
@media (max-width: 1150px) {
    .previous-status-container .selected-time  {
        font-size: 0.8rem;
    }
}

@media (max-width: 1000px) {
    .previous-status-container .selected-time  {
        font-size: 0.7rem;
    }
}

@media (max-width: 830px) {
    .previous-status-container .selected-time  {
        font-size: 0.6rem;
    }
}

@media (max-width: 700px) {
    /*.previous-status-container .selected-time  {*/
    /*    flex-direction: column;*/
    /*    justify-content: space-around;*/
    /*}*/
}

@media (max-width: 390px) {
    .previous-status-container .selected-time  {
        font-size: 0.5rem;
    }
}

/*========
Data Cards
========*/
.previous-status-container .data-cards {
    animation: onLoadAnimation 1s;
}

@keyframes onLoadAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/*-------------------
No Data For Selection
 ------------------*/
.previous-status-container .data-cards .no-data-for-selection {
    margin: 2em auto auto;
    color: #22428f;
    text-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
    animation: onLoadAnimation 1s;
}

.previous-status-container .data-cards .data-loading {
    margin: 2em auto auto;
    color: #22428f;
    text-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
    animation: onLoadAnimation 1s;
}

@keyframes onLoadAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


/*.selected-time .selectors #day-select {*/
/*    text-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);*/
/*    background: transparent;*/
/*}*/

/*.selected-time .selectors #time-select-hour {*/
/*    text-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);*/
/*    background: transparent;*/
/*}*/

/*.selected-time .selectors #time-select-min {*/
/*    text-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);*/
/*    background: transparent;*/
/*}*/



