/*=================
Data Info Container
 ================*/
.data-info-container {
    text-align: left;
    padding: 1em;
    margin-top: 1em;
    border-radius: 0.7rem;
    box-shadow: 2px 3px 7px rgba(0, 0, 0, 0.2);

    transition: box-shadow 200ms;
    animation: onLoadAnimation 1s;
}

.data-info-container:hover {
    box-shadow: 2px 5px 11px rgba(0, 0, 0, 0.2);
}

@keyframes onLoadAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/*---------
Media Query
 --------*/
@media (max-width: 1150px) {
    .data-info-container {
        margin-top: 1.3em;
    }

    .data-info-container h5 {
        font-size: 1.10rem;
    }
}

@media (max-width: 1000px) {
    .data-info-container {
        margin-top: 1.35em;
    }

    .data-info-container h5 {
        font-size: 0.95rem;
    }
}

@media (max-width: 830px) {
    .data-info-container {
        margin-top: 1.4em;
    }

    .data-info-container h5 {
        font-size: 0.90rem;
    }
}

@media (max-width: 700px) {
    .data-info-container {
        margin-top: 1.0em;
    }

    .data-info-container h5 {
        margin-bottom: 0.4rem;
        font-size: 0.90rem;
    }
}

@media (max-width: 390px) {

    .data-info-container h5 {
        margin-bottom: 0.3rem;
        font-size: 0.75rem;
    }
}

@media (max-width: 330px) {

    .data-info-container h5 {
        margin-bottom: 0.2rem;
        font-size: 0.70rem;
    }
}

/*============
Time Data Send
 ===========*/

.data-info-container .time-data-send h5 {
    color: #22428f;
    display: flex;
    text-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
}

.data-info-container .time-data-send h5 > div {
    color: #A2AAAD;
    margin-left: 0.3em;
    text-shadow: none!important;
    /*text-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);*/
}

/*======
Location
======*/
.data-info-container .location {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-shadow: 0 6px 13px rgba(0, 0, 0, 0.2);
}

.data-info-container .location .fas.fa-map-marker-alt {
    color: #22428f;
    margin-right: 0.5em;
}
