/*======================
Mission Select Container
======================*/
.mission-select-container {
    width: 100%;
    margin: 3em auto;
    padding: 2em;
    border-radius: 0.7rem;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
    animation: onLoadAnimation 1s;
}

@keyframes onLoadAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/*---------
Media Query
 --------*/
@media (max-width: 1150px) {
    .mission-select-container {
        font-size: 0.8rem;
    }

    .mission-select-container h3 {
        font-size: 1.60rem;
    }

    .mission-select-container h6 {
        font-size: 0.85rem;
    }
}

@media (max-width: 1000px) {
    .mission-select-container {
        font-size: 0.7rem;
    }

    .mission-select-container h3 {
        font-size: 1.45rem;
    }

    .mission-select-container h6 {
        font-size: 0.70rem;
    }
}

@media (max-width: 830px) {
    .mission-select-container {
        font-size: 0.6rem;
    }

    .mission-select-container h3 {
        font-size: 1.30rem;
    }

    .mission-select-container h6 {
        font-size: 0.55rem;
    }
}

@media (max-width: 700px) {
    .mission-select-container {
        flex-direction: column;
        justify-content: space-around;
    }

    .mission-select-container h3 {
        margin-bottom: 0.4rem;
        font-size: 1.30rem;
    }

    .mission-select-container h6 {
        margin-bottom: 0.4rem;
        font-size: 0.55rem;
    }
}

@media (max-width: 390px) {
    .mission-select-container {
        font-size: 0.5rem;
    }

    .mission-select-container h3 {
        margin-bottom: 0.3rem;
        font-size: 1.15rem;
    }

    .mission-select-container h6 {
        margin-bottom: 0.3rem;
        font-size: 0.40rem;
    }
}

/*============
Mission Status
============*/
.mission-select-container .mission-status {
    display: flex;
}

/*-----
Heading
-----*/
.mission-select-container .mission-status .heading > h3 {
    color: #22428f;
    text-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
}

/*------------
Active Mission
------------*/
.mission-select-container .mission-status .active-mission {
    align-self: center;
    padding-left: 1em;
}

.mission-select-container .mission-status .active-mission > h6 {
    color: #A2AAAD;
    margin-bottom: 0.3rem;
}


/*======
Missions
======*/
.mission-select-container .missions {
    display: flex;
    flex-flow: wrap;
}

/*=============
Mission Buttons
=============*/
.mission-select-container .missions {

}

/*-----------------
Mission Name Button
 ----------------*/
.mission-select-container .missions > button {
    background: transparent;
    padding: 1em;
    margin: 1em 1em 1em 0;
    border: none;
    border-radius: 0.7rem;
    text-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);

    transition: box-shadow 200ms;
}

.mission-select-container .missions > button:hover {
    box-shadow: 2px 5px 11px rgba(0, 0, 0, 0.2);
}

.mission-select-container .missions > button .far.fa-check-circle {
    color: #28a745;
}

/*--------------------------
Mission Status Toggle Button
 -------------------------*/
.mission-select-container .mission-file-status-button button {

}

/*---------------------
Mission Settings Button
 --------------------*/
.mission-select-container .settings-btn {
    display: flex;
    justify-content: flex-end;
}

.mission-select-container .settings-btn button {
    background: #22428f;
    color: white;
    border: none;
    border-radius: 0.7rem;
    text-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);

    transition: box-shadow 200ms;
}

.mission-select-container .settings-btn > button:hover {
    box-shadow: 2px 5px 11px rgba(0, 0, 0, 0.2);
}

.mission-select-container .settings-btn button .fas.fa-cogs {
    margin-left: 0.5em;
}