/*====================
Message Card Container
====================*/
.message-card-container {
    width: 100%;
    margin: 3em auto;
    padding: 2em;
    border-radius: 0.7rem;
    display: flex;
    justify-content: space-around;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);

    animation: onLoadAnimation 1s;
}

@keyframes onLoadAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/*=======
Main Info
 ======*/
.message-card-container .main-info {
    text-align: left;
}

/*---------
Media Query
 --------*/
@media (max-width: 1150px) {
    .message-card-container {
        font-size: 0.8rem;
    }

    .message-card-container h3 {
        font-size: 1.60rem;
    }

    .message-card-container h6 {
        font-size: 0.85rem;
    }
}

@media (max-width: 1000px) {
    .message-card-container {
        font-size: 0.7rem;
    }

    .message-card-container h3 {
        font-size: 1.45rem;
    }

    .message-card-container h6 {
        font-size: 0.70rem;
    }
}

@media (max-width: 830px) {
    .message-card-container {
        font-size: 0.6rem;
    }

    .message-card-container h3 {
        font-size: 1.30rem;
    }

    .message-card-container h6 {
        font-size: 0.55rem;
    }
}

@media (max-width: 700px) {
    .message-card-container {
        flex-direction: column;
        justify-content: space-around;
    }

    .message-card-container h3 {
        margin-bottom: 0.4rem;
        font-size: 1.30rem;
    }

    .message-card-container h6 {
        margin-bottom: 0.4rem;
        font-size: 0.55rem;
    }
}

@media (max-width: 390px) {
    .message-card-container {
        font-size: 0.5rem;
    }

    .message-card-container h3 {
        margin-bottom: 0.3rem;
        font-size: 1.15rem;
    }

    .message-card-container h6 {
        margin-bottom: 0.3rem;
        font-size: 0.40rem;
    }
}

/*---------
Message Day
---------*/
.message-card-container  .main-info .day > h3 {
    color: #22428f;
    margin: 0;
    padding-bottom: 0.77em;
    text-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
}

/*===========
Vertical Line
 ==========*/

.message-card-container .vertical-line {
    border-right: 2px solid #A2AAAD;
    filter: opacity(0.2);
    height: auto;
}

/*---------
Media Query
 --------*/
@media (max-width: 700px) {
    .message-card-container .vertical-line {
        border-right: none;
        border-bottom: 2px solid #A2AAAD;
        margin: 1em 0;
        width: auto;
        height: 2px;
    }
}

/*======
Messages
 =====*/
.message-card-container .messages {
    padding-top: 1.3em;
}

/*---------
Media Query
 --------*/
@media (max-width: 1150px) {
    .message-card-container .messages {
        padding-top: 1.6em;
    }
}

@media (max-width: 1000px) {
    .message-card-container .messages {
        padding-top: 1.7em;
    }
}

@media (max-width: 700px) {
    .message-card-container .messages {
        padding-top: 1.3em;
    }
}

/*-----
Message
-----*/
.message-card-container .messages .message {
    padding: 1em;
    margin-top: 1em;
    border-radius: 0.7rem;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);

    transition: box-shadow 200ms;
}

.message-card-container .messages .message:hover {
    box-shadow: 2px 5px 11px rgba(0, 0, 0, 0.2);
}

/*--
Text
--*/
.message-card-container .message .text > h5 {
    color: #22428f;
    text-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);;
}

/*--
Time
--*/
.message-card-container .message .time {

}

/*---------
Media Query
 --------*/
@media (max-width: 1150px) {
    .message-card-container .messages .message {
        margin-top: 1.3em;
    }

    .message-card-container .messages .message h5 {
        font-size: 1.10rem;
    }
}

@media (max-width: 1000px) {
    .message-card-container .messages .message {
        margin-top: 1.35em;
    }

    .message-card-container .messages .message h5 {
        font-size: 0.95rem;
    }
}

@media (max-width: 830px) {
    .message-card-container .messages .message {
        margin-top: 1.4em;
    }

    .message-card-container .messages .message h5 {
        font-size: 0.90rem;
    }
}

@media (max-width: 700px) {
    .message-card-container .messages .message {
        margin-top: 1.0em;
    }

    .message-card-container .messages .message h5 {
        margin-bottom: 0.4rem;
        font-size: 0.90rem;
    }
}

@media (max-width: 390px) {

    .message-card-container .messages .message h5 {
        margin-bottom: 0.3rem;
        font-size: 0.75rem;
    }
}

@media (max-width: 330px) {

    .message-card-container .messages .message h5 {
        margin-bottom: 0.2rem;
        font-size: 0.70rem;
    }
}

