/*=====================
UserDashBoard Container
 ====================*/
.adminDashboard-container {
    animation: onLoadAnimation 1s;
}

@media (max-width: 530px) {
    .userDashboard-container {
        display: block;
    }
}

/*========
Navigation
 =======*/

.adminDashboard-container .info-container .navigation {
    border-radius: 0.7rem;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-around;
}

.adminDashboard-container .info-container .navigation button {
    background: transparent;
    border: none;
    height: 100%;
    padding: 0.5em 0 0 0;
    position: relative;
}

.adminDashboard-container .info-container .navigation button > div {
    background: darkgray;
    width: 75%;
    height: 3px;
    margin: 0.5em auto 0 auto;
    text-align: center;

    opacity: 0;
    transform: scaleX(0);
    transform-origin: center;
    transition: transform 0.3s ease-out, opacity 0.3s ease-out;

}

.adminDashboard-container .info-container .navigation button:hover div  {
    transform: scaleX(1);
    opacity: 1;
}

.adminDashboard-container .info-container .navigation button:focus {
    outline: 0;
    color: #22428f;

    transition: color 0.3s ease-out;
}
.adminDashboard-container .info-container .navigation button:focus div {
    transform: scaleX(1);
    background: #22428f;
    opacity: 1;

    transition: background 0.3s ease-out;
}

.adminDashboard-container .info-container .navigation button:active div {
    transform: scaleX(1);
    background: #22428f;
    opacity: 1;

    transition: background 0.3s ease-out;
}

/*---------
Media Query
 --------*/
@media (max-width: 870px) {
    .adminDashboard-container .info-container .navigation > button {
        font-size: 0.8rem;
    }
}

@media (max-width: 700px) {
    .adminDashboard-container .map-container {
        margin: 2.5em auto;
    }
    .adminDashboard-container .info-container .navigation > button {
        font-size: 0.6rem;
    }
}

@media (max-width: 580px) {
    .adminDashboard-container .info-container .navigation > button {
        font-size: 0.55rem;
    }
}

@media (max-width: 450px) {
    .adminDashboard-container .info-container .navigation > button {
        font-size: 0.45rem;
    }
}