/*==========================
Phone Verification Container
 =========================*/
.phone-verification-container {
}

/*===
Modal
===*/
.phone-verification-modal {
}

/*==========
Modal Content
===========*/
.phone-verification-modal .modal-dialog .modal-content {
    border-radius: 0.7rem;
}

/*==================
Modal Content Header
==================*/
.phone-verification-modal .modal-dialog .modal-content .modal-header {
}

/*---
Title
---*/
.phone-verification-modal .modal-dialog .modal-content .modal-header .modal-title {
    color: #22428f;
    text-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
}

/*================
Modal Content Body
================*/
.phone-verification-modal .modal-dialog .modal-content .modal-body {
}

/*--
Form
--*/
.phone-verification-modal .modal-dialog .modal-content .modal-body form {
}

.phone-verification-modal .modal-dialog .modal-content .modal-body form .account-info-msg {
    animation: onLoadAnimation 1s;
}

.phone-verification-modal .modal-dialog .modal-content .modal-body form .form-group label {
    color: #22428f;
}

.phone-verification-modal .modal-dialog .modal-content .modal-body form .form-group input {
    color: #282c34;
}

/*==================
Modal Content Footer
==================*/
.phone-verification-modal .modal-dialog .modal-content .modal-footer {
}

/*----
Button
----*/
.phone-verification-modal .modal-dialog .modal-content .modal-footer button.re-send-code {
    background: #282c34;
    border-radius: 0.7rem;
    border: none;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);

    transition: all 200ms;
}

.phone-verification-modal .modal-dialog .modal-content .modal-footer button.verify-code {
    background: #22428f;
    border-radius: 0.7rem;
    border: none;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);

    transition: all 200ms;
}

.phone-verification-modal .modal-dialog .modal-content .modal-footer button:hover {
    box-shadow: 2px 5px 11px rgba(0, 0, 0, 0.2);
}

@keyframes onLoadAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}