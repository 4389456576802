/*====================
User Account Container
 ===================*/
.user-account-container {
    border-radius: 0.7rem;
    border: none;
    padding: 2em;
    margin: auto;
    width: 80%;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
    animation: onLoadAnimation 1s;
}

@keyframes onLoadAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/*=======
User Info
 ======*/
.user-account-container > div {
    text-align: left;
    margin-top: 1em;
}

.user-account-container hr {
    margin-top: 0.3rem;
    border: 0;
    border-top: 1px solid rgba(0,0,0,.1);
}
/*---------
Sub Heading
 --------*/
.user-account-container div h6.sub-heading {
    padding-left: 5em;
    color: #22428f;
    display: inline-flex;
    text-align: start;
    text-shadow: 0 6px 13px rgba(0, 0, 0, 0.2);
}

/*-------
Sub Value
 ------*/
.user-account-container div h6.sub-value {
    color: black;
    float: right;
    padding-right: 5em;
    display: inline-flex;
    text-shadow: 0 6px 13px rgba(0, 0, 0, 0.2);
}