/*=====================
UserDashBoard Container
 ====================*/
.userDashboard-container {
    animation: onLoadAnimation 1s;
}

@media (max-width: 530px) {
    .userDashboard-container {
        display: block;
    }
}

/*================
UserDashBoard Map
 ================*/
.userDashboard-container .map-container {
    position: relative;
    height: 500px;
}

/*================
UserDashBoard Info
 ================*/
.userDashboard-container .info-container {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;

    /*For testing*/
    height: 500px;
}

/*--------
Navigation
 -------*/

.userDashboard-container .info-container .navigation {
    border-radius: 0.7rem;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-around;
}

.userDashboard-container .info-container .navigation button {
    background: transparent;
    border: none;
    height: 100%;
    padding: 0.5em 0 0 0;
    position: relative;
}

.userDashboard-container .info-container .navigation button > div {
    background: darkgray;
    width: 75%;
    height: 3px;
    margin: 0.5em auto 0 auto;
    text-align: center;

    opacity: 0;
    transform: scaleX(0);
    transform-origin: center;
    transition: transform 0.3s ease-out, opacity 0.3s ease-out;

}

.userDashboard-container .info-container .navigation button:hover div  {
    transform: scaleX(1);
    opacity: 1;
}

.userDashboard-container .info-container .navigation button:focus {
    outline: 0;
    color: #22428f;

    transition: color 0.3s ease-out;
}
.userDashboard-container .info-container .navigation button:focus div {
    transform: scaleX(1);
    background: #22428f;
    opacity: 1;

    transition: background 0.3s ease-out;
}

.userDashboard-container .info-container .navigation button:active div {
    transform: scaleX(1);
    background: #22428f;
    opacity: 1;

    transition: background 0.3s ease-out;
}

/*---------
Media Query
 --------*/
@media (max-width: 870px) {
    .userDashboard-container .info-container .navigation > button {
        font-size: 0.8rem;
    }
}

@media (max-width: 700px) {
    .userDashboard-container .map-container {
        margin: 2.5em auto;
    }
    .userDashboard-container .info-container .navigation > button {
        font-size: 0.6rem;
    }
}

@media (max-width: 580px) {
    .userDashboard-container .info-container .navigation > button {
        font-size: 0.55rem;
    }
}

@media (max-width: 450px) {
    .userDashboard-container .info-container .navigation > button {
        font-size: 0.45rem;
    }
}

/*===============
UserDashBoard Map
 ==============*/

/*------
Map Info
------*/
.userDashboard-container .map-info-container {

}

/*-----------
Map Container
-----------*/
.userDashboard-container .map-container {
    display: flex;
    width: 90%;
    height: 600px;
    margin: 3em auto;
}

.userDashboard-container .map-container .map {
    height: 100%;/*override from 30%*/
    border-radius: 0.7rem;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
}

.userDashboard-container .map-container > div {
    width: 80%;
}

.userDashboard-container .map-container > div > div {
    position: static!important;
}


/*---------
Media Query
 --------*/
@media (max-width: 700px) {
    .userDashboard-container .map-container {
        margin: 2.5em auto;
    }

    .userDashboard-container .map-container > div {
        width: 100%;
    }

    .userDashboard-container .map-container > div > div {
        position: absolute!important;
    }

    .userDashboard-container .map-container > div > div > div {
        left: 0!important;
    }
}

@media (max-width: 580px) {
    .userDashboard-container .map-container {
        margin: 2em auto;
    }
}

@media (max-width: 450px) {
    .userDashboard-container .map-container {
        margin: 1.5em auto;
    }
}

@keyframes onLoadAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
