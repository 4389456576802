
/*===============
Navbar Links List
================*/
.navbar-container .navbar {
    display: flex;
    padding: 1em 0em;
    justify-content: flex-end;
}

.navbar-container .navbar {
    position: static;
}

.navbar-container .navbar .navbar-logo {
    text-align: start;
    padding-left: 1em;
}

.navbar-container .navbar .navbar-links {
    /*text-align: end;*/
    width: 100%;
    display: grid;
    grid-auto-flow: column;
}

.navbar-container .navbar .navbar-links .links-list {
    list-style-type: none;
    display: inline-flex;
    margin-bottom: 0;
    margin-right: 1em;

    justify-content: flex-end;
    align-items: center;
}

.navbar-container .navbar .navbar-links .links-list  a {
    text-decoration: none;
    margin: 1em;
    color: #22428f;

    font-style: normal;
    font-size: 0.87rem;
    line-height: 1em;
    font-family: Adagio_Sans, myriad-pro, sans-serif;
    font-weight: normal;
    letter-spacing: 2px;
    text-transform: uppercase;

    text-shadow: 0 6px 13px rgba(0, 0, 0, 0.2);

    transition: all 200ms ease-in;

    white-space: nowrap;
    max-width: fit-content;
    /*Test*/
    /*padding: 8px 8px 8px 32px;*/
    /*text-decoration: none;*/
    /*font-size: 25px;*/
    /*color: #818181;*/
    /*display: block;*/
    /*transition: 0.3s;*/
}

.navbar-container .navbar .navbar-links .links-list  a:hover {
    color: darkgrey;
    transition: all 150ms ease-in;
}

.navbar-container .navbar .navbar-links .links-list .sign-in-link {

    border-radius: 7px;
}

.navbar-container .navbar .navbar-links .links-list .sign-in-link a {
    color: white;
    background: #22428f;
    padding: 0.4em;
    border-radius: 7px;
    margin: 0;

    transition: all 200ms ease-in;
}

.navbar-container .navbar .navbar-links .links-list .sign-in-link a:hover {
    background: #A2AAAD;
    border-radius: 5px;
}

.navbar-container .navbar .navbar-links .links-list li .user-icon {
    /*width: 78px;*/
}

.open-side-nav  {
    width: 50%!important;
}

/*==============
Side Nav Toggles
==============*/

.navbar-container .navbar .navbar-links .sidenav-open-toggle {
    display: none;

}

.navbar-container .navbar .navbar-links .sidenav-open-toggle i {
    color: #22428f;
}

.navbar-container .navbar .navbar-links .links-list li .sidenav-close-toggle {
    visibility: hidden;
    display: none;
    text-align: start;
}

.navbar-container .navbar .navbar-links .links-list li .sidenav-close-toggle i {
    color: #22428f;
}

.display {
    visibility: visible!important;
}

/*===========
Media Queries
============*/

@media (max-width: 870px) {
    .navbar-container .navbar .navbar-links .links-list  li a,
    .navbar-container .navbar .navbar-links .links-list .dropdown .dropbtn,
    .navbar-container .navbar .navbar-links .links-list  li .sign-in-link a {
        font-size: 0.6rem;
    }
}

@media (max-width: 700px) {
    .navbar-container .navbar .navbar-links .links-list  li a,
    .navbar-container .navbar .navbar-links .links-list .dropdown .dropbtn,
    .navbar-container .navbar .navbar-links .links-list  li .sign-in-link a {
        font-size: 0.5rem;
    }
}

@media (max-width: 650px) {
    .navbar-container .navbar .navbar-links .navbar-logo{
        align-self: center;
    }

    .navbar-container .navbar .navbar-links .navbar-logo img {
        width: 5em;
    }
}

@media (max-width: 580px) {
    .navbar-container .navbar .navbar-links .links-list  li a,
    .navbar-container .navbar .navbar-links .links-list .dropdown .dropbtn,
    .navbar-container .navbar .navbar-links .links-list  li .sign-in-link a {
        font-size: 0.4rem;
    }
}

@media (max-width: 530px) {
    .navbar-container .navbar .navbar-links .links-list {
        position: fixed;
        width: 0;
        height: 100%;
        z-index: 2;
        margin: 0 0;
        padding: 0;
        top: 0;
        right: 0;
        overflow-x: hidden;
        transition: 0.5s;

        background: white;
    }

    .navbar-container .navbar .navbar-links .links-list {
        display: block;
    }

    .navbar-container .navbar .navbar-links .links-list li .user-icon {
        display: block;
        width: 100%;
        margin: auto;
    }

    .navbar-container .navbar .navbar-links .sidenav-open-toggle {
        display: block;
        justify-self: end;
        margin-right: 1em;
    }

    .navbar-container .navbar .navbar-links .links-list li .sidenav-close-toggle {
        display: block;
    }

}

/*@media (max-width: 500px) {*/
/*    .navbar-container .navbar .navbar-links .links-list {*/
/*        width: 50%;*/
/*    }*/
/*}*/

/*======================
Navbar User Account icon
=======================*/
.navbar-container .navbar .navbar-links .links-list .fas {
    color: #22428f;
    padding: 0.23em;
}