/*======================
Current Status Container
======================*/
.current-status-container {
    display: none;
    width: 100%;
    margin: 0 auto;
}

/*---------
Media Query
---------*/
@media (max-width: 700px) {
    .current-status-container {
        display: block;
    }
}

/*======================
Data Cards
======================*/
.current-status-container .data-cards {
}