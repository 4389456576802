.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

main {
  width: 90%;
  margin: 0 auto;
  left: 0;
  right: 0;
  transition: left 0.5s, right 0.5s!important;
}

.side-nav-open-main {
  position: fixed;
  transition: left 0.5s, right 0.5s, filter 0.3s!important;
  filter: blur(1px);
  opacity: 0.3;
  left: -50%!important;
  right: 50% !important;

  /*In Case main slide is replaced with overlay*/
  /*margin-right: 50%;*/
  /*overflow-x: hidden;*/
  /*white-space: nowrap;*/
}

.side-nav-open-hr {
  margin-right: 0;
  margin-left: 0;
  width: 50%;

  transition: 0.5s;
}
