/*====================
Message Logs Container
 ===================*/
.message-logs-container {

}

/*=====
Heading
 ====*/
.message-logs-container > .heading {
    margin: 1em;
}

.message-logs-container .heading h5 {
    color: #22428f;
    text-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
}

/*---------
Media Query
 --------*/
@media (max-width: 1150px) {
    .message-logs-container .heading h5 {
        font-size: 1.15rem;
    }
}

@media (max-width: 1000px) {
    .message-logs-container .heading h5 {
        font-size: 1.10rem;
    }
}

@media (max-width: 830px) {
    .message-logs-container .heading h5 {
        font-size: 1.10rem;
    }
}

@media (max-width: 700px) {
    .message-logs-container .heading h5 {
        margin-bottom: 0.4rem;
        font-size: 0.90rem;
    }
}

@media (max-width: 390px) {
    .message-logs-container .heading h5 {
        margin-bottom: 0.3rem;
        font-size: 0.80rem;
    }
}

@media (max-width: 330px) {
    .message-logs-container .heading h5 {
        margin-bottom: 0.2rem;
        font-size: 0.70rem;
    }
}

/*==================
Selected Message Day
 =================*/
.message-logs-container .selected-message-day {
    display: flex;
    margin: 0 auto;
    width: fit-content;
}

/*-------
Selectors
-------*/
.message-logs-container .selected-message-day .selectors {
    border-radius: 0.7rem;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);

    transition: box-shadow 200ms;
}

.message-logs-container .selected-message-day .selectors:hover {
    box-shadow: 2px 5px 11px rgba(0, 0, 0, 0.2);
}

.message-logs-container .selected-message-day .selectors > select {
    margin: 0.5em;
    height: 1.5em;
    background: transparent;
    border: none;
    /*text-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);*/
    /*box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);*/
}

/*---------------------
Selectors Search Button
---------------------*/
.message-logs-container .selected-message-day .search-button > button {
    background: #22428f;
    padding: 0.5em 0.7em;
    margin-left: 1em;
    color: white;
    border-radius: 0.7rem;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);

    transition: box-shadow 200ms, background 200ms, outline 200ms;
}

.message-logs-container .selected-message-day .search-button > button:focus {
    outline: none;
    background: #A2AAAD;
}

.message-logs-container .selected-message-day .search-button > button:hover {
    box-shadow: 2px 5px 11px rgba(0, 0, 0, 0.2);
}

/*---------
Media Query
 --------*/
@media (max-width: 1150px) {
    .message-logs-container .selected-message-day {
        font-size: 0.8rem;
    }
}

@media (max-width: 1000px) {
    .message-logs-container .selected-message-day {
        font-size: 0.7rem;
    }
}

@media (max-width: 830px) {
    .message-logs-container .selected-message-day {
        font-size: 0.6rem;
    }
}

@media (max-width: 700px) {
    /*.previous-status-container .selected-time  {*/
    /*    flex-direction: column;*/
    /*    justify-content: space-around;*/
    /*}*/
}

@media (max-width: 390px) {
    .message-logs-container .selected-message-day {
        font-size: 0.5rem;
    }
}