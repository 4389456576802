/*================
Accounts Container
================*/
.accounts-container {
    animation: onLoadAnimation 1s;
}

@keyframes onLoadAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/*==========
Add New User
==========*/

/*----
Button
----*/
.accounts-container .add-new-user button {
    display: inline-flex;
    justify-content: center;
    float: left;
    color: white;
    padding: 0.3em 1em;
    margin: 0.5em 0 1em 0;
    background: #22428f;
    border: none;
    width: 11.5em;
    cursor: pointer;
    height: 100%;
    position: relative;
    border-radius: 0.7rem;
    text-align: center;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
    animation: onLoadAnimation 1s;

    transition: all 200ms;
}

.accounts-container .add-new-user button:hover {
    box-shadow: 2px 5px 11px rgba(0, 0, 0, 0.2);
}

/*--
Span
--*/
.accounts-container .add-new-user button span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
    font-size: 1rem;
    vertical-align: middle;
}

.accounts-container .add-new-user button span:after {
    content: '+';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -3px;
    transition: 0.5s;
}

.accounts-container .add-new-user button:hover span {
    padding-right: 25px;
}

.accounts-container .add-new-user button:hover span:after {
    opacity: 1;
    right: 0;
}

/*---------
Media Query
 --------*/
@media (max-width: 870px) {
    .accounts-container .add-new-user button {
        width: 9.5em;
    }
    .accounts-container .add-new-user button span {
        font-size: 0.8rem;
    }
}

@media (max-width: 700px) {
    .accounts-container .add-new-user button {
        width: 8.0em;
        padding: 0.3em 1em;
    }
    .accounts-container .add-new-user button span {
        font-size: 0.6rem;
    }
}

@media (max-width: 580px) {
    .accounts-container .add-new-user button {
        width: 7.5em;
    }
    .accounts-container .add-new-user button span {
        font-size: 0.55rem;
    }
}

@media (max-width: 450px) {
    .accounts-container .add-new-user button {
        width: 6.7em;
    }
    .accounts-container .add-new-user button span {
        font-size: 0.45rem;
    }
}

/*==========
Account List
==========*/
.accounts-container .account-list {
}

/*---
Table
---*/
.accounts-container .account-list .table-responsive table {
    animation: onLoadAnimation 1s;
    width: 100%;
}

.accounts-container .account-list .table-responsive table,th, td  {
    /*width: max-content;*/
}

/*---------
Media Query
 --------*/
@media (max-width: 1550px) {
    .accounts-container .account-list .table-responsive table,th, td  {
        width: max-content;
    }
}

/*--------
Table Head
--------*/
.accounts-container .account-list .table-responsive table thead th {
    color: #22428f;
    font-weight: 700;
    letter-spacing: 2px;
}

/*--------
Table Body
--------*/
.accounts-container .account-list .table-responsive table tbody tr td {
}

.accounts-container .account-list .table-responsive table tbody tr .edit-account-btn button {
    border: none;
    color: #22428f;
    background: transparent;
    text-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);

    transition: all 200ms;
}

.accounts-container .account-list .table-responsive table tbody tr .edit-account-btn button:focus {
    outline: 0;
    color: #282c34;
}

.accounts-container .account-list .table-responsive table tbody tr .edit-account-btn button:hover {
    text-shadow: 2px 5px 11px rgba(0, 0, 0, 0.2);
}

/*---------
Media Query
 --------*/
@media (max-width: 870px) {
    .accounts-container .account-list {
        font-size: 0.8rem;
    }
}

@media (max-width: 700px) {
    .accounts-container .account-list {
        font-size: 0.6rem;
    }
}

@media (max-width: 580px) {
    .accounts-container .account-list {
        font-size: 0.55rem;
    }
}

@media (max-width: 450px) {
    .accounts-container .account-list {
        font-size: 0.45rem;
    }
}



