/*================
Map Info Container
================*/
.map-info-container {
    height: 100%;
    width: 20%!important;
    padding: 1em;
    margin-right: 1em;
    background: transparent;
    border-radius: 0.7rem;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);

    transition: box-shadow 200ms;
}

.map-info-container:hover {
    box-shadow: 2px 5px 11px rgba(0, 0, 0, 0.2);
}


/*============
Mission Status
============*/
.map-info-container .mission-status > h5{
    color: #22428f;
    margin: 0;
    padding-bottom: 0.77em;
    text-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
}

/*=======
Main Info
=======*/
.map-info-container .main-info {

}

/*----------
Sub Headings
----------*/
.map-info-container .main-info .sub-heading {
    font-weight: 500;
    letter-spacing: 1px;
    margin-bottom: 0;
    text-shadow: 0 3px 17px rgba(0, 0, 0, 0.2);
}

/*-
 H6
-*/
.map-info-container .main-info h6 {
    font-weight: 300;
}

.map-info-container .main-info .last-communication .sub-value.time {
    margin-bottom: 0;
}

/*=======
Data Info
=======*/
.map-info-container .data-info {

}

/*----------
Sub Headings
----------*/
.map-info-container .data-info .sub-heading {
    font-weight: 500;
    letter-spacing: 1px;
    margin-bottom: 0;
    text-shadow: 0 3px 17px rgba(0, 0, 0, 0.2);
}

/*-------
Sub Value
-------*/
.map-info-container .main-info .current-mission-time .sub-value.time {
    margin-bottom: 0;
}


/*-
 H6
-*/
.map-info-container .data-info h6 {
    font-weight: 300;
}

/*---------
Media Query
---------*/
@media (max-width: 1150px) {
    .map-info-container h6 {
        font-size: 0.85rem;
    }

    .map-info-container .main-info div {
        margin-bottom: 0.8em;
    }

    .map-info-container .data-info div {
        margin-bottom: 0.8em;
    }
}

@media (max-width: 1000px) {
    .map-info-container h6 {
        font-size: 0.70rem;
    }

    .map-info-container .main-info div {
        margin-bottom: 0.9em;
    }

    .map-info-container .data-info div {
        margin-bottom: 0.9em;
    }
}

@media (max-width: 830px) {
    .map-info-container h6 {
        font-size: 0.55rem;
    }

    .map-info-container .main-info div {
        margin-bottom: 1em;
    }

    .map-info-container .data-info div {
        margin-bottom: 1em;
    }
}

@media (max-width: 700px) {
    .map-info-container {
        display: none;
    }
}

