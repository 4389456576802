.backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    display: none;
    z-index: 1;
}

.show-backdrop {
    display: block!important;
}