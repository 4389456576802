/*===========*/
/*DropDown*/
/*===========*/
.dropdown {
    position: relative;
}

/*=============*/
/*DropDown Btn*/
/*===========*/
.dropdown .dropbtn {
    background: transparent;
    border: none;
    font-family: Adagio_Sans, myriad-pro, sans-serif;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #22428f;
    white-space: nowrap;

    text-shadow: 0 6px 13px rgba(0, 0, 0, 0.2);
}

.dropdown:hover .dropbtn {
    color: darkgrey;

    transition: all 200ms ease-in;
}

/*=================*/
/*DropDown Content*/
/*===============*/
.dropdown .dropdown-content {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    width: max-content;
    transform: scaleY(0);
    transform-origin: top;
    z-index: 1;

    text-align: left;
    background: white;
    border: none;
    border-radius: 0px  0px 5px 5px;
    color: transparent;
    box-shadow: 0 5px 7px rgba(0, 0, 0, 0.2);

    transition: all 200ms ease-in;
}

.dropdown:hover .dropdown-content {
     visibility: visible;
     opacity: 1;
     transform: scaleY(1);
     transform-origin: top;
}

/*===========================*/
/*DropDown Content Container*/
/*=========================*/
.dropdown .dropdown-content .container a {
    display: block;
    cursor: pointer;
    font-size: 14px;
    text-shadow: 0 6px 13px rgba(0, 0, 0, 0.2);
}

.dropdown .dropdown-content .container a:first-child {
    margin-top: 25px;
}

.dropdown .dropdown-content .container button {
    margin: 0 0 0 1em;
    /*margin-left: 1em;*/
    padding: 0px;
    border: none;
    background: transparent;
    color: #22428f;

    font-style: normal;
    line-height: 1em;
    font-family: Adagio_Sans, myriad-pro, sans-serif;
    font-weight: normal;
    letter-spacing: 2px;
    text-transform: uppercase;

    text-shadow: 0 6px 13px rgba(0, 0, 0, 0.2);
    transition: all 200ms ease-in;
}

.dropdown .dropdown-content .container button:first-child {
    margin-top: 25px;
}

.dropdown .dropdown-content .container button:hover {
    color: darkgrey;
}

.dropdown .dropdown-content .container {
    opacity: 0;
    visibility: hidden;

    transition: all 125ms ease-in
}

.dropdown:hover .dropdown-content .container {
    opacity: 1;
    visibility: visible;

    transition: all 125ms 75ms ease-in
}

.dropdown .rightZero .container a {
    margin: 25px 0 1em 0;
}

.rightZero {
    right: 0.5em;
    text-align: start;
}

/*===========
Media Queries
===========*/

@media (max-width: 870px) {
    .dropdown .dropdown-content .container button {
        font-size: 0.6rem;
    }
}

@media (max-width: 700px) {
    .dropdown .dropdown-content .container button {
        font-size: 0.5rem;
    }
}

@media (max-width: 580px) {
    .dropdown .dropdown-content .container button {
        font-size: 0.4rem;
    }
}

@media (max-width: 530px) {
    .dropdown .dropdown-content {
        position: static;
        width: 100%;
        border-radius: 0;
        box-shadow: none;
        text-align: center;
        max-height: 0;

        transition: all 200ms ease-in;
    }

    .dropdown:hover .dropdown-content {
        max-height: 10%;
        /*background: lightsteelblue;*/
    }

    .dropdown .dropdown-content .container {
        padding-bottom: 0.7em;
        text-align: -webkit-center;
    }

    .dropdown .dropdown-content .container a:first-child {
        padding-top: 2em;
        margin-top: 0;
    }

    .dropdown .dropdown-content .container button {
        margin: 0;
        font-size: 0.4rem;

        font-style: normal;
        line-height: 1em;
        font-family: Adagio_Sans, myriad-pro, sans-serif;
        font-weight: normal;
        letter-spacing: 2px;
        text-transform: uppercase;

        text-shadow: 0 6px 13px rgba(0, 0, 0, 0.2);;
    }
}

/*@media (max-width: 500px) {*/
/*    .dropdown .dropdown-content .container {*/
/*        text-align: -webkit-center;*/
/*    }*/
/*}*/

