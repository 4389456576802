/*=================
Data Card Container
 ================*/
.data-card-container {
    width: 100%;
    margin: 3em auto;
    padding: 2em;
    border-radius: 0.7rem;
    display: flex;
    justify-content: space-around;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);

    animation: onLoadAnimation 1s;
}

@keyframes onLoadAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/*=======
Main Info
 ======*/
.data-card-container .main-info {
    text-align: left;
}

/*------------------
Current Mission Day
------------------*/
.data-card-container .current-mission-day h3 {
    color: #22428f;
    margin: 0;
    padding-bottom: 0.77em;
    text-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
}

 /*------------------
 Current Mission Name
 ------------------*/
.data-card-container .current-mission-name {

}

/*---------
Media Query
 --------*/
@media (max-width: 1150px) {
    .data-card-container {
        font-size: 0.8rem;
    }

    .data-card-container h3 {
        font-size: 1.60rem;
    }

    .data-card-container h6 {
        font-size: 0.85rem;
    }
}

@media (max-width: 1000px) {
    .data-card-container {
        font-size: 0.7rem;
    }

    .data-card-container h3 {
        font-size: 1.45rem;
    }

    .data-card-container h6 {
        font-size: 0.70rem;
    }
}

@media (max-width: 830px) {
    .data-card-container {
        font-size: 0.6rem;
    }

    .data-card-container h3 {
        font-size: 1.30rem;
    }

    .data-card-container h6 {
        font-size: 0.55rem;
    }
}

@media (max-width: 700px) {
    .data-card-container {
        flex-direction: column;
        justify-content: space-around;
    }

    .data-card-container h3 {
        margin-bottom: 0.4rem;
        font-size: 1.30rem;
    }

    .data-card-container h6 {
        margin-bottom: 0.4rem;
        font-size: 0.55rem;
    }
}

@media (max-width: 390px) {
    .data-card-container {
        font-size: 0.5rem;
    }

    .data-card-container h3 {
        margin-bottom: 0.3rem;
        font-size: 1.15rem;
    }

    .data-card-container h6 {
        margin-bottom: 0.3rem;
        font-size: 0.40rem;
    }
}

/*===========
Vertical Line
 ==========*/

.data-card-container .vertical-line {
    border-right: 2px solid #A2AAAD;
    filter: opacity(0.2);
    height: auto;
}

/*---------
Media Query
 --------*/
@media (max-width: 700px) {
    .data-card-container .vertical-line {
        border-right: none;
        border-bottom: 2px solid #A2AAAD;
        margin: 1em 0;
        width: auto;
        height: 2px;
    }
}

/*=======
Data Info
 ======*/

.data-card-container .data-info {
    padding-top: 1.3em;
}

/*---------
Media Query
 --------*/
@media (max-width: 1150px) {
    .data-card-container .data-info {
        padding-top: 1.6em;
    }
}

@media (max-width: 1000px) {
    .data-card-container .data-info {
        padding-top: 1.7em;
    }
}

@media (max-width: 700px) {
    .data-card-container .data-info {
        padding-top: 1.3em;
    }
}