.map-test {
    display: flex;
}

.svg-inline--fa.fa-dot-circle.fa-w-16 {
    color: red;
}

#vehicle-marker {
    color: red!important;
}

.info-window-container .location {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.info-window-container .location .fas.fa-map-marker-alt {
    color: #22428f;
    margin-right: 0.5em;
 }