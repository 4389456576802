/*=============================
Account Change Modal Container
=============================*/
.accountInfoChangeModal-container {
}


/*===
Modal
===*/
/*Since Modal is Not Actually Located Inside the div(.addNewAccountModal-container) by React Bootstrap*/
.account-info-change-modal {
}

/*==========
Modal Content
===========*/
.account-info-change-modal .modal-dialog .modal-content {
    border-radius: 0.7rem;
}

/*==================
Modal Content Header
==================*/
.account-info-change-modal .modal-dialog .modal-content .modal-header {
}

/*---
Title
---*/
.account-info-change-modal .modal-dialog .modal-content .modal-header .modal-title {
    color: #22428f;
    text-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
}

/*================
Modal Content Body
================*/
.account-info-change-modal .modal-dialog .modal-content .modal-body {
}

/*--
Form
--*/
.account-info-change-modal .modal-dialog .modal-content .modal-body form {
}

.account-info-change-modal .modal-dialog .modal-content .modal-body form .form-group label {
    color: #22428f;
}

.account-info-change-modal .modal-dialog .modal-content .modal-body form .form-group label.cellula_staff_reference {
    font-size: xx-small;
    color: #282c34;
}

.account-info-change-modal .modal-dialog .modal-content .modal-body form .form-group input {
    color: #282c34;
}

.account-info-change-modal .modal-dialog .modal-content .modal-body form .form-group.buttons button {
    background: #22428f;
    color: white;
    border: none;
    border-radius: 0.7rem;
    margin-right: 0.5em;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);

    transition: all 200ms;
}

.account-info-change-modal .modal-dialog .modal-content .modal-body form .form-group.buttons button:hover {
    box-shadow: 2px 5px 11px rgba(0, 0, 0, 0.2);
}

/*==================
Modal Content Footer
==================*/
.account-info-change-modal .modal-dialog .modal-content .modal-footer {
}

/*----
Button
----*/
.account-info-change-modal .modal-dialog .modal-content .modal-footer .close-btn {
    background: #282c34;
    border-radius: 0.7rem;
    border: none;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);

    transition: all 200ms;
}

.account-info-change-modal .modal-dialog .modal-content .modal-footer .confirm-btn {
    background: #22428f;
    border-radius: 0.7rem;
    border: none;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);

    transition: all 200ms;
}

.account-info-change-modal .modal-dialog .modal-content .modal-footer button:hover {
    box-shadow: 2px 5px 11px rgba(0, 0, 0, 0.2);
}